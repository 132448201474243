<template>
  <v-container fluid>
    <v-row>
      <v-col class="col-6">
        <h1>Archived Leave Requests</h1>
      </v-col>
      <v-col class="col-6 text-right">
        <router-link to="/admin/leave-requests">
          <v-btn>View Current Requests</v-btn>
        </router-link>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="col-12">
        <v-form @submit.prevent="setSearchParams">
          <v-row class="pt-8">
            <v-col>
              <label>Personnel</label>
              <v-text-field v-model="person"></v-text-field>
            </v-col>
            <v-col>
              <v-dialog
                  ref="dialog"
                  v-model="modal"
                  :return-value.sync="date"
                  persistent
                  width="350px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <label class="pl-8">Date</label>
                  <v-text-field
                      v-model="date"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                    v-model="dateProto"
                    range>
                  <v-spacer></v-spacer>
                  <v-btn
                      text
                      color="primary"
                      @click="modal = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                      text
                      color="primary"
                      @click="$refs.dialog.save(date)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-dialog>
            </v-col>
            <v-col class="pt-10">
              <v-btn small type="sumbit">Search</v-btn>
              &nbsp;
              <v-btn small @click="clearSearch">Clear</v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="col-12">
        <v-simple-table
            fixed-header
            height="450px"
        >
          <template v-slot:default>
            <thead>
            <tr>
              <th class="text-left">Person</th>
              <th class="text-left">Days Requested</th>
              <th class="text-left">Start Date</th>
              <th class="text-left">End Date</th>
              <th class="text-left">Coverage</th>
            </tr>
            </thead>
            <tbody>
            <tr
                v-for="(request, index) in requests"
                :key="index"
            >
              <td>{{ request.personnel.first_name }} {{ request.personnel.last_name }}</td>
              <td>{{ request.total_days }}</td>
              <td>{{ request.first_day | prettyDate }}</td>
              <td>{{ request.last_day | prettyDate }}</td>
              <td>{{ request.coverage }}</td>
            </tr>
            </tbody>
          </template>
        </v-simple-table>
        <v-pagination
            class="pt-4"
            v-model="page"
            :length="length"
            prev-icon="mdi-menu-left"
            next-icon="mdi-menu-right"
            :total-visible="totalVisible"
        ></v-pagination>
      </v-col>
    </v-row>
    <router-view></router-view>
  </v-container>
</template>

<script>
import axios from '../../../axios';

export default {
  name: 'LeaveRequestsArchive',
  computed: {
    token() {
      return this.$store.state.token;
    },
    userid() {
      return this.$store.state.userid;
    },
  },
  data() {
    return {
      requests: [],
      date: [],
      dateProto: [],
      dateDialogue: false,
      modal: false,
      page: 1,
      person: '',
      length: 0,
      totalVisible: 9,
    };
  },
  watch: {
    page() {
      this.getLeaveRequests();
    },
    dateProto () {
      this.date = this.formatDate(this.dateProto);
    },
  },
  methods: {
    formatDate(date) {
      const formattedDate = [];
      if (!date) return null;
      if (date[0]) {
        const [year, month, day] = date[0].split('-');
        formattedDate[0] = `${day}-${month}-${year}`;
      }
      if (date[1]) {
        const [year, month, day] = date[1].split('-');
        formattedDate[1] = `${day}-${month}-${year}`;
      }
      return formattedDate;
    },
    clearSearch() {
      this.person = '';
      this.date = [];
      this.requests = [];
      this.setSearchParams();
    },
    getSearchParams() {
      this.person = this.$store.state.leaveRequestsSearchParams.person;
      this.date = this.$store.state.leaveRequestsSearchParams.date;
    },
    setSearchParams() {
      const params = {};
      params.person = this.person;
      params.date = this.date;
      this.$store.commit('setLeaveRequestsSearchParams', params);
      this.getLeaveRequests();
    },
    getLeaveRequests() {
      const postData = {
        person: this.person,
        date: this.date,
      };
      axios.post(`/leaveRequests/getArchived/${this.page}.json?token=${this.token}`, postData)
          .then((response) => {
            this.requests = response.data.leaveRequests;
            this.length = response.data.length;
            this.total = response.data.total;
          })
          .catch((error) => {
            console.log(error);
          });
    },
  },
  mounted() {
    this.getSearchParams();
    setTimeout(() => {
      this.getLeaveRequests();
    }, 500);
  },
};
</script>
